import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { Col } from "../components/utilities"
import { Layout } from "../components/layouts/Layout"
import { Banner, Section } from "../components/elements"

const Image = styled.img`
  border-radius: 20px;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`

const SpaceFlex = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${Col} {
    box-sizing: border-box;
    padding: 1rem;
    width: 30%;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 30%;
  }
`

const OurSpace = ({ data }) => {
  const {
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
  } = data
  const arr = [
    img2,
    img3,
    img6,
    img5,
    img1,
    img4,
    img7,
    img8,
    img9,
    img13,
    img10,
    img11,
    img19,
    img18,
    img14,
    img15,
    img16,
    img17,
    img12,
  ]

  return (
    <Layout title="Our Space">
      <Banner title="our space" />
      <Section>
        <h3>Our Space</h3>
        <SpaceFlex>
          {arr.map((img, ix) => (
            <Col key={ix}>
              {img && <Image src={img?.childImageSharp?.fluid?.src} />}
            </Col>
          ))}
        </SpaceFlex>
      </Section>
    </Layout>
  )
}

export default OurSpace

export const query = graphql`
  query OurSpaceQuery {
    img1: file(relativePath: { eq: "space1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          src
        }
      }
    }
    img2: file(relativePath: { eq: "space2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          src
        }
      }
    }
    img3: file(relativePath: { eq: "space3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          src
        }
      }
    }
    img4: file(relativePath: { eq: "space4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          src
        }
      }
    }
    img5: file(relativePath: { eq: "space5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          src
        }
      }
    }
    img6: file(relativePath: { eq: "space6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          src
        }
      }
    }
    img7: file(relativePath: { eq: "space7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          src
        }
      }
    }
    img8: file(relativePath: { eq: "space8.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          src
        }
      }
    }
    img9: file(relativePath: { eq: "space9.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          src
        }
      }
    }
    img10: file(relativePath: { eq: "space10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          src
        }
      }
    }
    img11: file(relativePath: { eq: "space11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          src
        }
      }
    }
    img12: file(relativePath: { eq: "space12.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          src
        }
      }
    }
    img13: file(relativePath: { eq: "space13.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          src
        }
      }
    }
    img14: file(relativePath: { eq: "space14.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          src
        }
      }
    }
    img15: file(relativePath: { eq: "space15.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          src
        }
      }
    }
    img16: file(relativePath: { eq: "space16.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          src
        }
      }
    }
    img17: file(relativePath: { eq: "space17.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          src
        }
      }
    }
    img18: file(relativePath: { eq: "space18.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          src
        }
      }
    }
    img19: file(relativePath: { eq: "space19.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          src
        }
      }
    }
  }
`
